import { render, staticRenderFns } from "./BroadcastList.vue?vue&type=template&id=f77fc762&scoped=true&lang=pug"
import script from "./BroadcastList.vue?vue&type=script&lang=js"
export * from "./BroadcastList.vue?vue&type=script&lang=js"
import style0 from "./BroadcastList.vue?vue&type=style&index=0&id=f77fc762&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f77fc762",
  null
  
)

export default component.exports